import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as dat from 'dat.gui'
/* import CANNON, { Vec3 } from 'cannon' */
import CANNON, { Body } from 'cannon'
import { ACESFilmicToneMapping, CineonToneMapping, DoubleSide, ReinhardToneMapping } from 'three'




/**
 * --------------- BASE THREE.JS SET UP - SCENE, LIGHTS, CAMERA
 */
const loadingManager = new THREE.LoadingManager();
const textureLoader = new THREE.TextureLoader(loadingManager);

const colorTexture = textureLoader.load('/textures/dice_colour.png');
const backgroundTexture = textureLoader.load('/textures/bg.jpg');

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//scene.background = new THREE.Color('#000000')
scene.background = backgroundTexture


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 1.2)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 40
directionalLight.shadow.camera.left = - 30
directionalLight.shadow.camera.top = 50
directionalLight.shadow.camera.right = 30
directionalLight.shadow.camera.bottom = - 30 
directionalLight.position.set(5, 5, 5)
directionalLight.shadow.bias = -0.01
scene.add(directionalLight)


/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () =>
{
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.set(-14, 8, 0)



scene.add(camera)


// RAYCASTER

const raycaster = new THREE.Raycaster();

const mouse = new THREE.Vector2();

window.addEventListener('mousemove', function(event){
  
  mouse.x = event.clientX / sizes.width * 2 - 1;
  mouse.y = - (event.clientY / sizes.height) * 2 + 1;

});

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxDistance = 20
controls.maxPolarAngle = Math.PI / 2 - 0.2


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = ReinhardToneMapping
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


// The X axis is red. The Y axis is green. The Z axis is blue
/* const axesHelper = new THREE.AxesHelper(10,10,10)
axesHelper.visible = false
scene.add(axesHelper) */
/**
 * Debug
 */
const gui = new dat.GUI()

const debugObject = {
  color: 0x119DA4,
  sideColor: 0x19647E
};
// CREATE OBJECTS (SPHERES AND BOXES) WHEN GENERATED USING THE GUI. THESE ATTRIBUTES WILL BE STORED IN THE DEBUGOBJECTS ARRAY

debugObject.createBox = () =>
{
    createBox(
      1,1,1,
     
       { 
        
         x: -8,
         y: 2,
         z: 0.5
        });
}
gui.add(debugObject, 'createBox').name('Roll the dice');

/* gui.add(camera.position, 'x').min(-16).max(0).step(0.1)
gui.add(camera.position, 'y').min(1.5).max(20).step(0.1)
gui.add(camera.position, 'z').min(-10).max(10).step(0.1)
 */
/* gui.addColor(debugObject, 'color').onChange(function(){
  baizeMaterial.color.set(debugObject.color)
})
gui.addColor(debugObject, 'sideColor').onChange(function(){
  baizeSideMaterial.color.set(debugObject.sideColor)
}) */
//gui.add(axesHelper, 'visible').name('axes helper')

gui.add(directionalLight, 'intensity').min(0).max(4).step(0.1).name('direction light intensity')
gui.add(ambientLight, 'intensity').min(0.2).max(4).step(0.01).name('ambient light intensity')





// LOAD DICE OBJECT AND STORE IT IN VAR DICE FOR USE LATER
let dice;
const gltfLoader = new GLTFLoader()
gltfLoader.load(
  '/models/dice_with_bump.glb',
  (gltf) =>
  {
      //scene.add(gltf.scene.children[0])
      dice = gltf.scene.children[0]
      dice.castShadow = true
      dice.receiveShadow = true
     /*  dice.position.set(0,0.5,0)
      dice.rotation.set(0,0,0)
      scene.add(dice)
      console.log(dice)
      console.log(dice.getWorldDirection(new THREE.Vector3()))
      gui.add(dice.rotation, 'x').min(0).max(Math.PI * 2).step(Math.PI / 8).name('Dice Rotation X').onFinishChange(function(){ console.log()})
      gui.add(dice.rotation, 'y').min(0).max(Math.PI * 2).step(Math.PI / 8).name('Dice Rotation Y').onFinishChange(function(){ console.log()})
      gui.add(dice.rotation, 'z').min(0).max(Math.PI * 2).step(Math.PI / 8).name('Dice Rotation Z').onFinishChange(function(){ console.log()}) */
  }
)
let diceBox;
gltfLoader.load(
  '/models/dice_box4.glb',
  (gltf) =>
  {
      //scene.add(gltf.scene.children[0])
      //diceBox = gltf.scene.children[0]
      diceBox = gltf.scene
      diceBox.traverse( function( child ) {
        if ( child instanceof THREE.Mesh ) { 
          child.receiveShadow = true;
          //child.castShadow = true 
        }
      } );
      //diceBox.receiveShadow = true
      //diceBox.castShadow = true
     //dice.position.set(0,0.5,0)
      diceBox.rotation.set(0,Math.PI, 0)
      scene.add(diceBox)
      
  }
)

// PLAYING AREA SET-UP

/* 
// THREE.JS BAIZE MATERIAL
// Baize Material
const baizeMaterial = new THREE.MeshStandardMaterial({
  
  metalness: 0.0,
  roughness: 0.6,
  side: DoubleSide
  
})
baizeMaterial.color.set(debugObject.color)

// Baize Material
const baizeSideMaterial = new THREE.MeshStandardMaterial({
  
  metalness: 0.0,
  roughness: 0.6,
  side: DoubleSide
  
})
baizeSideMaterial.color.set(debugObject.sideColor)
// ----------------- FLOOR --------------------


const floor = new THREE.Mesh(
  new THREE.PlaneGeometry(20, 20),
  baizeMaterial
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
//scene.add(floor)


// ----------------- LEFT WALL --------------------

const leftWall = new THREE.Mesh(
  new THREE.PlaneGeometry(20, 8),
  baizeSideMaterial
)
 leftWall.receiveShadow = true
 leftWall.position.set(0,2,-10)

//scene.add( leftWall)


// ----------------- RIGHT WALL --------------------

const rightWall = new THREE.Mesh(
  new THREE.PlaneGeometry(20, 8),
  baizeSideMaterial
)
 rightWall.receiveShadow = true
 rightWall.position.set(0,2,10)

//scene.add( rightWall)


// ----------------- BACK WALL --------------------

const backWall = new THREE.Mesh(
  new THREE.PlaneGeometry(20, 8),
  baizeSideMaterial
)
 backWall.receiveShadow = true
 backWall.rotation.y = Math.PI/2
 backWall.position.set(10,2,0)
//floor.rotation.x = - Math.PI * 0.5
//scene.add( backWall)

// ----------------- BACK WALL --------------------

const frontWall = new THREE.Mesh(
  new THREE.PlaneGeometry(20, 8),
  baizeSideMaterial
)
 frontWall.receiveShadow = true
 frontWall.rotation.y = Math.PI/2
 frontWall.position.set(-10,2,0)
//floor.rotation.x = - Math.PI * 0.5
//scene.add( frontWall)
 */




//var cannonDebugRenderer = new THREE.CannonDebugRenderer( scene, world );
/* const axesHelper = new THREE.AxesHelper(10);
scene.add(axesHelper); */



// PHYSICS - WORLD
const world = new CANNON.World();
world.gravity.set(0, -9.82, 0);


// PHYSICS - CONTACT MATERIALS
const defaultMaterial = new CANNON.Material('default');

const baizeMaterialCannon = new CANNON.Material('baize')
const diceMaterialCannon = new CANNON.Material('dice')

const baizeDiceContactMaterial = new CANNON.ContactMaterial(
    baizeMaterialCannon,
    diceMaterialCannon,
  {
      friction: 0.6,
      restitution: 0.1
  }
)
world.addContactMaterial(baizeDiceContactMaterial)

const diceDiceContactMaterial = new CANNON.ContactMaterial(
  diceMaterialCannon,
  diceMaterialCannon,
  {
      friction: 0.5,
      restitution: 0.1
  }
)
world.addContactMaterial(diceDiceContactMaterial)

world.broadphase = new CANNON.SAPBroadphase(world);
world.allowSleep = true;





// PLAYING AREA SET-UP

// ----------------- FLOOR --------------------

// CANNON

const floorShape = new CANNON.Plane(new CANNON.Vec3(5, 5,1))
const floorBody = new CANNON.Body()
floorBody.material = baizeMaterialCannon;
floorBody.mass = 0
floorBody.addShape(floorShape);//
world.addBody(floorBody)
// ROTATE PLANE FROM THE DEFAULT WHICH IS FACING THE CAMERA
floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(- 1, 0, 0), Math.PI * 0.5) 

// ----------------- LEFT WALL --------------------

// CANNON

const leftWallShape = new CANNON.Plane(new CANNON.Vec3(5, 5,1))
const leftWallBody = new CANNON.Body()
leftWallBody.material = baizeMaterialCannon;
leftWallBody.mass = 0
leftWallBody.addShape(leftWallShape);

world.addBody(leftWallBody) 

leftWallBody.position.set(-3,0,-10);


// ----------------- RIGHT WALL --------------------

// CANNON
const rightWallShape = new CANNON.Plane(new CANNON.Vec3(5, 5,1))
const rightWallBody = new CANNON.Body()
rightWallBody.material = baizeMaterialCannon;
rightWallBody.mass = 0
rightWallBody.addShape(rightWallShape);

world.addBody(rightWallBody)
rightWallBody.position.set(0,0,10);
// ROTATE PLANE 90 DEGREES THROUGH THE Y AXES
rightWallBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0, -1, 0), Math.PI) 


// ----------------- BACK WALL --------------------

// CANNON
const backWallShape = new CANNON.Plane(new CANNON.Vec3(5, 5,1))
const backWallBody = new CANNON.Body()
backWallBody.material = baizeMaterialCannon;
backWallBody.mass = 0
backWallBody.addShape(backWallShape);
backWallBody.position.set(10,-10,0); 
// ROTATE PLANE 90 DEGREES THROUGH THE Y AXES
backWallBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0, -1, 0), Math.PI * 0.5) 
world.addBody(backWallBody)


// ----------------- FRONT WALL --------------------

// CANNON
const frontWallShape = new CANNON.Plane(new CANNON.Vec3(5, 5,1))
const frontWallBody = new CANNON.Body()
frontWallBody.material = baizeMaterialCannon;
frontWallBody.mass = 0
frontWallBody.addShape(frontWallShape);
frontWallBody.position.set(-11,-10,0); 
// ROTATE PLANE 90 DEGREES THROUGH THE Y AXES
frontWallBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0, -1, 0), Math.PI * 0.5) 
//world.addBody(frontWallBody)



// UTILITY FUNCTIONS

// SPHERE
 const objectsToUpdate = [];



const createBox = (width, height, depth, position) =>
{
    // Three.js mesh
   //const mesh = new THREE.Mesh(boxGeometry, boxMaterial)
   const mesh = dice.clone()
    mesh.scale.set(width, height, depth)
    mesh.castShadow = true
    mesh.position.copy(position)
    scene.add(mesh)



    // Cannon.js body
    const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5))

    const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(0, 3, -1),
        shape: shape,
        material: diceMaterialCannon
    })
    body.position.copy(position)

    const max = 0.2;
    const min = 0.05;

    const directionXMin = -1200;
    const directionXMax = 1200;

    const forceDirection = new CANNON.Vec3(1500, -100, 0)
    // APPLY AN INITIAL FORCE OF FORWARD:600, HEIGHT:0, RIGHT:200 TO THE BOX
    // SECOND VECTOR 3 IS WHICH PART OF THE BOX WE APPLY THE FORCE TO. BY CHANGING THE VALUES FROM 0,0,0 WE CAN CAUSE INITIAL ROTATION
    //body.applyLocalForce(forceDirection, new CANNON.Vec3(Math.random() * (max - min) + min, Math.random() * (max - min) + min, Math.random() * (max - min) + min));
    body.applyLocalForce(forceDirection, new CANNON.Vec3(0.1, Math.random() * (max - min) + min, 0.05));
    world.addBody(body)

    // Save in objects
    objectsToUpdate.push({ mesh, body })
}

console.log(objectsToUpdate)
/**
 * Animate
 */
const clock = new THREE.Clock()
let oldElapsedTime = 0;
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime;
    oldElapsedTime = elapsedTime;

    world.step( 1 / 60, deltaTime, 3);

    for (const object of objectsToUpdate){
      object.mesh.position.copy(object.body.position);
      object.mesh.quaternion.copy(object.body.quaternion)
    }
    
    camera.lookAt(new THREE.Vector3(10,0,0))

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()